<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :iconLeft="statusIcon(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "reports-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "open":
          return "Offen";
        case "inProgress":
          return "In Bearbeitung";
        case "toBeReviewed":
          return "Zu prüfen";
        case "closed":
          return "Geschlossen";
        default:
          return "Fehler";
      }
    },
    statusIcon(statusId) {
      switch (statusId) {
        case "open":
          return "history";
        case "inProgress":
          return "pencil-box-multiple";
        case "toBeReviewed":
          return "check-all";
        case "closed":
          return "archive-check";
        default:
          return "alert";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "open":
          return "red";
        case "inProgress":
          return "orange";
        case "toBeReviewed":
          return "purple";
        case "closed":
          return "green";
        default:
          return "grey";
      }
    },
  },
};
</script>
