<template>
  <div>
    <NavigationBar
      titel="Berichte"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col
              v-for="data in statisticsData"
              :key="data.title"
              md="4"
              sm="4"
              class="align-center"
            >
              <statistics-single-card
                :title="data.title"
                :color="data.color"
                :icon="data.icon"
                :value="data.value"
              ></statistics-single-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-archive" title="Berichte"> </Subheader>
              <div class="mt-7">
                <reports-data-table :reports="reports"></reports-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.reportCreationDialog.model"
      @close="toggleCreationDialog()"
      title="Berichtsart auswählen"
      :loading="helpers.reportCreationDialog.loading"
      :btnAction="createReport"
      :btnDisabled="!helpers.reportCreationDialog.selectedReportTypeId"
      :errorMessage="
        (!reportTypes.length
          ? 'Es sind keine Berichtsarten vorhanden. Bitte einen Administrator die Erstellung von Dienstberichten einzurichten und Berichtsarten anzulegen.'
          : helpers.reportCreationDialog.errorMessage) ||
        helpers.reportCreationDialog.errorMessage
      "
      width="m"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <br />
        <v-form
          v-model="helpers.reportCreationDialog.formIsValid"
          @submit.prevent="createReport()"
        >
          <v-item-group
            v-if="reportTypes.length"
            v-model="helpers.reportCreationDialog.selectedReportTypeId"
            mandatory
          >
            <v-row class="match-height">
              <v-col
                v-for="reportType in reportTypes"
                :key="reportType.meta.id"
                cols="12"
                md="6"
              >
                <v-item v-slot="{ active, toggle }" :value="reportType.meta.id">
                  <v-card
                    outlined
                    @click="toggle"
                    :style="
                      active ? `border: 3px solid #1867c0 !important` : ''
                    "
                  >
                    <v-list-item>
                      <v-list-item-icon>
                        <v-avatar
                          :color="`${reportType.color} lighten-5`"
                          rounded
                        >
                          <v-icon :color="reportType.color">
                            {{ reportType.icon }}
                          </v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                          {{ reportType.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ reportType.subtitle }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-form>
      </template>
    </custom-dialog>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ReportsDataTable from "@/components/duty-management/ReportsDataTable.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORT_TYPES, GET_REPORTS } from "@/store/action-types.js";
import { DUTY_MANAGEMENT_REPORTS_create } from "@/data/permission-types.js";
export default {
  name: "duty-management-reports",
  components: {
    NavigationBar,
    ReportsDataTable,
    Subheader,
    CustomDialog,
    SupportTools,
    StatisticsSingleCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${DUTY_MANAGEMENT_REPORTS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.toggleCreationDialog,
        },
      ],
      helpers: {
        displayMode: "table",
        reportCreationDialog: {
          model: false,
          loading: false,
          errorMessage: "",
          selectedReportTypeId: "",
        },
      },

      statisticsData: [
        {
          title: "Offen",
          icon: "mdi-history",
          color: "red",
          value: "4",
        },
        {
          title: "In Bearbeitung",
          icon: "mdi-pencil-box-multiple",
          color: "orange",
          value: "2",
        },
        {
          title: "Zu prüfen",
          icon: "mdi-check-all",
          color: "green",
          value: "4",
        },
      ],
    };
  },
  computed: {
    reports() {
      return this.$store.state.dutyManagement.reports;
    },
    reportTypes() {
      return this.$store.state.dutyManagement.reportTypes;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "reports", data: this.reports },
        { title: "reportTypes", data: this.reportTypes },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORTS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    toggleCreationDialog() {
      this.helpers.reportCreationDialog.model =
        !this.helpers.reportCreationDialog.model;
    },
    createReport() {
      this.$router.push({
        name: "duty-management-reports-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
        query: {
          reportType: this.helpers.reportCreationDialog.selectedReportTypeId,
        },
      });
    },
  },
};
</script>
